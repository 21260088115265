<template>
  <div>
    <b-card>
      <b-form @submit.prevent="fetch(1)">
        <b-row class="d-flex justify-content-between">

          <b-col>
            <b-row>
              <b-col>
                <b-form-group class="mr-1" label="Vehicule">
                  <b-form-input v-model="filter.q" placeholder="Marque, Immatriculation, VIN, N° entrée ..." />
                </b-form-group>

              </b-col>
              <b-col>
                <b-form-group label="Statut">
                  <v-select label="libelle" v-model="filter.statut" :reduce="loc => loc.id" :options="$store.state.initialConfigModule.initialState.livraison_status"></v-select>
                </b-form-group>
              </b-col>

            </b-row>
          </b-col>
          <b-col md="auto">
            <b-form-group label="-">
              <b-button
                  variant="primary"
                  type="submit"
              >
                Rechercher
              </b-button>
            </b-form-group>


          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-row>
      <b-col :key="item.id" v-for="item in items" md="6">
        <b-card no-body>
          <div class="d-flex justify-content-between p-1 mb-1 border-bottom align-items-center">

            <div class="d-flex align-items-center">
              <div class="mr-1">
                <feather-icon size="25" icon="FileTextIcon"></feather-icon>
              </div>
              <div>
                Commande n° <span class="font-weight-bold">{{ item.offre_id }}</span><br/>
                BDC clôturé le <span class="font-weight-bold">{{ item.date }}</span>
              </div>
            </div>

            <div>

              <b-badge :variant="item.statut.variant">{{ item.statut.libelle }}</b-badge>
            </div>
          </div>
          <b-card-body>
            <div class="d-flex justify-content-between">
              <vehicule-overview :with-image="false" :vehicule="item.vehicule"/>
              <div>
                <ul class="list-unstyled">
                  <li>
                    <feather-icon icon="UserIcon"></feather-icon>
                    <span class="ml-1 font-weight-bold">{{ item.client.full_name }}</span>
                  </li>
                  <li>
                    <feather-icon icon="MailIcon"></feather-icon>
                    <span class="ml-1 font-weight-bold">{{ item.client.email }}</span></li>
                  <li v-if="item.client.telephone">
                    <a :href="'tel:' + item.client.telephone">
                      <feather-icon icon="PhoneCallIcon"></feather-icon>
                      <span class="ml-1 font-weight-bold">{{ item.client.telephone }}</span>
                    </a>
                  </li>
                </ul>


              </div>
            </div>

          </b-card-body>
          <b-card-footer class="py-1">
            <b-card-text>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <div class="font-small-3">  <feather-icon icon="UsersIcon"></feather-icon> Vendeur : {{ item.vendeur.full_name }}</div>
                  <div class="font-small-3">
                    <feather-icon icon="MapPinIcon"></feather-icon>
                    {{ item.vehicule.site }}
                  </div>
                </div>
                <div>
                  <b-button v-if="item.statut.str_id == 'en_attente'" @click="passerEnAttenteHandler(item)" variant="flat-primary" size="sm">
                    <feather-icon icon="CheckIcon"></feather-icon>
                    Passer en attente</b-button>
                  <b-button v-if="item.statut.str_id == 'en_cours_traitement'" @click="traiterHandler(item)" variant="flat-success" size="sm">
                    <feather-icon icon="CheckIcon"></feather-icon>
                    Traité</b-button>
                </div>
              </div>
            </b-card-text>
          </b-card-footer>
        </b-card>

      </b-col>
    </b-row>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-number
              last-number
              @change="fetch"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VehiculeOverview from "@/components/Vehicule/VehiculeOverview";
import axios from "@/libs/axios";


export default {
  components: {
    VehiculeOverview,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      filter : {
        q : ''
      },
      pagination: {}
    }
  },

  created() {
    this.fetch(1);
  },
  methods: {
    passerEnAttenteHandler(item){
      let _this = this;
 
      axios
          .post('/api/livraison/' + item.id + '/passer-en-attente')
          .then(function (response) {
            _this.items = _this.items.map(function (i){
              return (i.id != response.data.data.id ? i : response.data.data);
            });
 
          });
    },
    traiterHandler(item){
      let _this = this;

      axios
          .post('/api/livraison/' + item.id + '/traiter')
          .then(function (response) {
            _this.items = _this.items.map(function (i){
              return (i.id != response.data.data.id ? i : response.data.data);
            });
          });
    },
    fetch(page) {
      let _this = this;
      this.$store.dispatch('livraisonModule/fetchLivraisons', {
        page: page,
        ..._this.filter
      }).then(function (response) {
        _this.items = response.data;
        _this.pagination = response.meta;
      })
    }
  },
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}

</style>
